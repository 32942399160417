import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Map from "../components/map"
import LastUpdate from "../components/last-update"
import Table from "../components/table"
import Total from "../components/total"
import LineChartHolder from "../components/linechart"
import { useStaticQuery, graphql } from "gatsby"
import canadaMapData from "../../static/canada.json"
import * as d3 from "d3"

const DataSourceReference = props => {
  return (
    <p
      id="data-source"
      dangerouslySetInnerHTML={{ __html: "Source: " + props.data }}
    />
  )
}

export default props => {
  const [completeData, setCompleteData] = useState([])
  const [provincesData, setProvincesData] = useState([])

  const gatsbyData = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          last_updated
          data_source
          faqs {
            question
            answer
          }
        }
      }
    }
  `)
  var provinces = [
    {
      province: "Alberta",
      cases: 0,
      deaths: 0,
      uid: "48",
      cx: 213.48,
      cy: 396,
    },
    {
      province: "Saskatchewan",
      cases: 0,
      deaths: 0,
      uid: "47",
      cx: 285,
      cy: 417,
    },
    { province: "Manitoba", cases: 0, deaths: 0, uid: "46", cx: 353, cy: 413 },
    {
      province: "Newfoundland",
      cases: 0,
      deaths: 0,
      uid: "10",
      cx: 628,
      cy: 354,
    },
    {
      province: "Prince Edward Island",
      cases: 0,
      deaths: 0,
      uid: "11",
      cx: 673,
      cy: 451,
    },
    {
      province: "Nova Scotia",
      cases: 0,
      deaths: 0,
      uid: "12",
      cx: 670,
      cy: 480,
    },
    {
      province: "Northwest Territories",
      cases: 0,
      deaths: 0,
      uid: "61",
      cx: 204,
      cy: 259,
    },
    { province: "Nunavut", cases: 0, deaths: 0, uid: "62", cx: 349, cy: 283 },
    { province: "Ontario", cases: 0, deaths: 0, uid: "35", cx: 442, cy: 449 },
    {
      province: "New Brunswick",
      cases: 0,
      deaths: 0,
      uid: "13",
      cx: 644,
      cy: 465,
    },
    { province: "Yukon", cases: 0, deaths: 0, uid: "60", cx: 113, cy: 244 },
    {
      province: "British Columbia",
      cases: 0,
      deaths: 0,
      uid: "59",
      cx: 134,
      cy: 375,
    },
    { province: "Quebec", cases: 0, deaths: 0, uid: "24", cx: 560, cy: 402 },
  ]

  useEffect(() => {
    d3.csv(
      "https://gauntlet-covid19.s3.ca-central-1.amazonaws.com/covid19.csv",
      (error, data) => {
        if (data) {
          provinces.forEach((province, index) => {
            const temp = data.filter((item, i) => item.pruid === province.uid)
            const newest = temp[temp.length - 1]
            province.cases = parseInt(newest.numconf.replace(",", ""))
            province.deaths = parseInt(newest.numdeaths.replace(",", ""))
          })

          setCompleteData(data)
          setProvincesData(provinces)
        }
      }
    )
  }, [])

  return (
    <Layout
      pageTitle={gatsbyData.site.siteMetadata.title}
      pageDescription={gatsbyData.site.siteMetadata.description}
    >
      <LastUpdate date={gatsbyData.site.siteMetadata.last_updated} />
      <DataSourceReference data={gatsbyData.site.siteMetadata.data_source} />
      <Map data={provincesData} mapData={canadaMapData} />
      <div className="container" id="barchart-container">
        <LineChartHolder data={completeData} another="hi" />
      </div>
      <div className="container">
        <div className="dummy-link" id="tables-link"></div>
        <div className="row" id="middle-info-holder">
          <Table data={provincesData} />
          <Total data={provincesData} />
        </div>
      </div>
    </Layout>
  )
}
