import React from "react"
import { numberWithCommas } from "../helpers/helper"
import { useStaticQuery, graphql } from "gatsby"

export default props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          recovered
          repatriated_travellers
        }
      }
    }
  `)
  const totalCases =
    props.data.reduce((total, item) => total + parseInt(item.cases), 0) +
    data.site.siteMetadata.repatriated_travellers[0]
  const totalDeaths =
    props.data.reduce((total, item) => total + parseInt(item.deaths), 0) +
    data.site.siteMetadata.repatriated_travellers[1]

  return (
    <div className="column center-text" id="total-holder">
      <div className="total-group">
        <h1 className="h-title">Total Confirmed</h1>
        <h1 className="h-value">{numberWithCommas(totalCases)}</h1>
      </div>
      <div className="total-group">
        <h1 className="h-title">Total Deaths</h1>
        <h1 className="h-value">{numberWithCommas(totalDeaths)}</h1>
      </div>
      <div className="total-group">
        <h1 className="h-title">Recovered</h1>
        <h1 className="h-value green">
          {numberWithCommas(data.site.siteMetadata.recovered)}
        </h1>
      </div>
    </div>
  )
}
