import React from "react"
import * as d3 from "d3"
import { numberWithCommas } from "../helpers/helper"

class LineChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      provinceId: this.props.provinceId,
    }
    this.svgRef = React.createRef()
  }

  createThePlot() {
    var margin = { top: 30, right: 20, bottom: 30, left: 35 },
      width = this.props.provinceId === "-1" ? 700 : 210,
      height = this.props.provinceId === "-1" ? 250 : 200

    const width_nomargin = width - margin.left - margin.right
    const height_nomargin = height - margin.bottom - margin.top

    // Parse the date / time
    var parseDate = d3.time.format("%d-%m-%Y").parse

    // Set the ranges
    var x = d3.time.scale().range([0, width_nomargin])
    var y = d3.scale.linear().range([height_nomargin, 0])

    // Define the axes
    var monthFormat = d3.time.format("%d-%m")

    var xAxis = d3.svg
      .axis()
      .scale(x)
      .orient("bottom")
      .ticks(0)
      .tickFormat(monthFormat)

    var yAxis = d3.svg
      .axis()
      .scale(y)
      .orient("left")
      .ticks(this.props.data[this.props.data.length - 1].numtotal > 5 ? 5 : 1)

    // Define the line
    var valueline = d3.svg
      .line()
      .x(function(d) {
        return x(d.date)
      })
      .y(function(d) {
        return y(d.cases)
      })

    // Adds the svg canvas
    var svg = d3
      .select(this.svgRef.current)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", `0 0 ${width} ${height}`)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    let data = this.props.data
    if (
      this.props.provinceId !== "99" &&
      data[data.length - 1].numconf === data[data.length - 2].numconf
    ) {
      data = data.slice(0, data.length - 1)
    }
    // Get the data
    data.forEach(function(d) {
      d.date = parseDate(d.date)
      d.cases = +parseInt(d.numconf.replace(",", ""))
    })

    // Scale the range of the data
    x.domain(
      d3.extent(data, function(d) {
        return d.date
      })
    )
    y.domain([
      0,
      d3.max(data, function(d) {
        return d.cases
      }),
    ])

    // Add the valueline path.
    svg
      .append("path")
      .attr("class", "line")
      .attr("d", valueline(data))

    svg
      .append("circle")
      .attr("cx", x(data[data.length - 1].date))
      .attr("cy", y(data[data.length - 1].cases))
      .attr("class", "end-circle")
      .attr("r", "1.4")

    // Add the X Axis
    svg
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height_nomargin + ")")
      .call(xAxis)

    // Add the Y Axis
    svg
      .append("g")
      .attr("class", "y axis")
      .call(yAxis)

    const province_name =
      data[0].prname.indexOf("Newfound") === -1
        ? data[0].prname
        : "Newfoundland"
    svg
      .append("text")
      .attr("x", width_nomargin / 2)
      .attr("y", height_nomargin + 15)
      .attr("text-anchor", "middle")
      .attr("class", "province-text")
      .text(
        province_name +
          " (" +
          numberWithCommas(data[data.length - 1].numconf) +
          ")"
      )
  }

  componentDidMount() {
    if (this.props.data.length > 0) {
      this.rendered = true
      this.createThePlot()
    }
  }

  componentDidUpdate() {
    if (this.props.data.length > 0 && !this.rendered) {
      this.rendered = true
      this.createThePlot()
    }
  }

  render() {
    const className = "column column-33"
    return (
      <div ref={this.svgRef} id="svg-line-chart" className={className}></div>
    )
  }
}

class LineChartHolder extends React.Component {
  constructor(props) {
    super(props)
    this.counter = 5
    this.state = {
      provinces: [
        { code: "24", data: [] },
        { code: "35", data: [] },
        { code: "48", data: [] },
        { code: "59", data: [] },
        { code: "12", data: [] },
        { code: "47", data: [] },
        { code: "10", data: [] },
        { code: "46", data: [] },
        { code: "13", data: [] },
        { code: "11", data: [] },
        { code: "60", data: [] },
        { code: "61", data: [] },
        { code: "62", data: [] },
        { code: "99", data: [] },
        { code: "1", data: [] },
      ],
    }
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    this.counter--
    if (this.state.provinces[0].data.length === 0 && this.counter > 0) {
      let provinces = []
      this.state.provinces.forEach((item, i) => {
        provinces[i] = {
          code: item.code,
          data: this.props.data.filter(d => d.pruid === item.code),
        }
      })

      this.setState({
        provinces,
      })
    }
  }

  render() {
    const linecharts = this.state.provinces.map((item, i) => (
      <LineChart provinceId={item.code} data={item.data} key={i} />
    ))
    let whole = []
    for (let i = 0; i < linecharts.length; i = i + 3) {
      whole.push(
        <div key={i} className="row">
          {linecharts.slice(i, i + 3)}
        </div>
      )
    }
    return <>{whole}</>
  }
}

export default LineChartHolder
