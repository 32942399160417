import React from "react"
import { numberWithCommas } from "../helpers/helper"
import { useStaticQuery, graphql } from "gatsby"

export default props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          recovered
          repatriated_travellers
        }
      }
    }
  `)
  let sorted = props.data.map(item => [item.province, item.cases, item.deaths])
  sorted.sort((first, second) => second[1] - first[1])
  const rows = sorted.map((row, i) => (
    <tr key={i}>
      <td>{row[0]}</td>
      <td>{numberWithCommas(row[1])}</td>
      <td>{numberWithCommas(row[2])}</td>
    </tr>
  ))
  return (
    <div className="column" id="table-holder">
      <table>
        <thead>
          <tr>
            <th>Province</th>
            <th>Cases</th>
            <th>Deaths</th>
          </tr>
        </thead>
        <tbody>
          {rows}
          <tr className="additional">
            <td>Repatriated Travellers</td>
            <td>{data.site.siteMetadata.repatriated_travellers[0]}</td>
            <td>{data.site.siteMetadata.repatriated_travellers[1]}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
